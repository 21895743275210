export const PACKAGE_HIGHLIGHTS = [
    "Direct Flight",
    "Best Accommodation",
    "Local Transportation",
    "Dedicated Tour Guide",
    "Ziyarat Included",
    "Complimentary Meals During Stay",
  ]
  
  export const PACKAGE_INCLUSIONS = {
    accommodation: ["One of the Best hotel in Makkah", "One of the Best hotel in Madinah"],
    transportation: ["Luxury buses", "Airport transfers"],
    meals: ["All meals included", "Breakfast ,Lunch and Dinner"],
    other: ["Visa processing assistance", "Complimentary Zamzam water"],
  }
  