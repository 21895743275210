import { useState } from 'react'
import ImageModal from '../../components/ui/ImageModal'

function GalleryGrid({ images }) {
  const [selectedImage, setSelectedImage] = useState(null)

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {images.map((image) => (
          <div
            key={image.id} // Use Firestore `id` for a unique key
            className="aspect-square cursor-pointer overflow-hidden rounded-lg"
            onClick={() => setSelectedImage(image)}
          >
            <img
              src={image.url} // URL from Firestore
              alt={image.caption}
              className="h-full w-full object-cover transition-transform hover:scale-110"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <ImageModal
          image={selectedImage.url}
          caption={selectedImage.caption}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </>
  )
}

export default GalleryGrid
